<template>
    <section class="car-info-dashboard">
        <ul class="car-info-dashboard__list">
            <li class="car-info-dashboard__item" v-for="(info, i) in unitInfo" :key="i">
                <div class="car-info-dashboard__icon" :class="{'main-power-icon': info.main_power}" >
                    <SvgIcon class="icon icon-color" :class="{'main-icon': info.main_power}" :name="info.icon" />
                    <SvgIcon v-if="info.statusIcon" class="icon icon-badge" :class="info.statusClass || ''" :name="info.statusIcon"  />
                    <span v-if="false" :vif="info.iconText" class="main-power-icon__text">{{info.iconText}}</span>
                </div>
                <div class="car-info-dashboard__text-wrap">
                    <div class="car-info-dashboard__title info-text">
                        {{ $t('Unit.'+info.title)  }}
                    </div>
                    <div CLASS="car-info-dashboard__subtitle block-title">
                        {{info.value}}
                    </div>
                </div>
            </li>
        </ul>
    </section>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: "Unit_info",
    props: {
        'value': {
            type: Object,
            default: () => { return {} }
        },
        showMap: {
            type: Boolean
        }
    },
    data(){
        return {
            carInfo: [
                {
                    title: 'Last message',
                    icon: 'unit_dashboard__massage',
                    value: () => { return this.timeAgoInfo }
                },
                {
                    title: 'Speed',
                    icon: 'unit_dashboard__speed',
                    statusClass: 'secondary',
                    statusIcon: 'unit_dashboard__check',
                    value: () => { return this.speed }
                },
                {
                    title: 'Status',
                    icon: 'unit_dashboard__status',
                    statusIcon: 'unit_dashboard__online',
                    value: () => { return this.unitStatus },
                    statusClass: () => { return this.timeAgoClass },
                },

                {
                    title: 'Ignition',
                    icon: 'unit_dashboard__ignition',
                    value: () => { return this.ignitionText },
                },
                {
                    title: 'Main Power',
                    icon: 'unit_dashboard__charge',
                    // iconText: () => { return  ''},
                    value: () => { return this.main_power },
                    main_power: true,
                },
                {
                    title: 'Mileage',
                    icon: 'unit_dashboard__mileage',
                    value: () => { return this.unitLmsgStatus.mileage || this.$t('Not defined') },
                },

                {
                    title: 'Battery',
                    icon: 'unit_dashboard__battery',
                    value: () => { return this.battery },
                },
                {
                    title: 'Temperature',
                    icon: 'unit_dashboard__temperature',
                    statusClass: 'danger',
                    statusIcon: 'unit_dashboard__check_danger',
                    value: () => { return this.temperature },
                },
            ],
        }
    },
    computed:{
        ...mapGetters([
            "getAppUserUnits",
            "unitsLmsgsByIds",
        ]),
        unit() {
            return this.value
        },
        speedUnits(){
            return this.getAppUserUnits.speed
        },
        maxSpeed(){
            return this.unit.max_speed > 0 ? this.unit.max_speed : ''
        },
        unitLmsg(){
            return this.unitsLmsgsByIds[this.unit.id] || {}
        },
        sensors(){
            return this.unitLmsg.sensors || {}
        },
        unitLmsgStatus(){
            return this.unitLmsg.status || {}
        },
        unitInfo(){
            let info = this.carInfo.map(c => {
                let value = c.value
                if(typeof value === 'function'){
                    value = value()
                }
                let statusClass = c.statusClass
                if(typeof statusClass === 'function'){
                    statusClass = statusClass()
                }
                // let iconText = c.iconText || ''
                // if(typeof iconText === 'function'){
                //     iconText = iconText()
                // }
                return {...c, value, statusClass}//, iconText}
            })
            while(info.length % 3){
                info.push({title:'Empty'})
            }
            return info
        },
        carIcon(){
            return this.unit.icon || 'map__car_type_pickup'
        },
        carTitle(){
            let list = []
            if(this.unit.name > '') list.push(this.unit.name)
            if(this.unit.model > '') list.push(this.unit.model)
            return  '' + list.join('/')
        },
        ignitionText(){
            let value = this.$t('Not defined')
            if(this.sensors.ign !== null && this.sensors.ign !== undefined) {
                if (this.sensors.ignition === true) value = this.$t('On')
                if (this.sensors.ignition === false) value = this.$t('Off')
            }
            return value
        },
        ignition(){
            return (this.unitLmsgStatus.ign !== null && this.unitLmsgStatus.ign !== undefined) ? this.unitLmsgStatus.ignition : null
        },
        isParked(){
            return this.unitLmsgStatus.isParked
        },
        isMoving(){
            return this.unitLmsgStatus.isMoving
        },
        main_power(){
            let sensor = this.sensors.main_power
            return sensor ? ((sensor.value || '-') + ' ' + (sensor.units)) : this.$t('Not defined')
        },
        battery(){
            let sensor = this.sensors.battery
            return sensor ? ((sensor.value || '-') + ' ' + (sensor.units)) : this.$t('Not defined')
        },
        temperature(){
            let sensor = this.sensors.temperature
            return sensor ? ((sensor.value || '-') + ' ' + (sensor.units)) : this.$t('Not defined')
        },
        speed(){
            let speed = this.unitLmsg.speed
            return (speed && speed > 0) ? (Math.round(speed) + ' ' + this.speedUnits) : '-'
        },
        timeAgo(){
            return this.unitLmsg.timeAgo
        },
        timeAgoInfo() {
            let timeAgo = this.timeAgo
            if (!timeAgo && timeAgo !== 0){
                return '-';
            }
            let sign = ''
            if(timeAgo<0){
                sign = '-'
                timeAgo *= -1
            }
            //return timeAgo
            let time = {
                y: Math.floor(timeAgo / (365 * 24 * 60 * 60)),
                d: Math.floor(timeAgo / (24 * 60 * 60)),
                h: Math.floor(timeAgo / (60 * 60)),
                m: Math.floor(timeAgo / (60)),
                s: Math.round(timeAgo / (1)),
            }
            // if(time.y) time = time.y+'y'
            // else
            if(time.d > 9) time = time.d+'d'
            else if(time.h) time = time.h+'h'
            else if(time.m) time = time.m+'m'
            else time = time.s+'s'

            //+'('+new Date(this.unitLmsg.time).toISOString()+')'
            return time ? sign + this.$t('{time} ago', {time}) : ''
        },
        timeAgoClass() {
            let timeAgo = this.timeAgo
            if (!timeAgo && timeAgo !== 0){
                return '';
            }
            //if(timeAgo<0) timeAgo *= -1

            if(timeAgo <= 5*60){
                return 'update_status_online'
            }else
            if(timeAgo <= 15*60){
                return 'update_status_online-recently'
            }else
            if(timeAgo <= 60*60){
                return 'update_status_not-online-recently'
            }else
            {
                return 'update_status_not-online-long-time'
            }
        },
        unitStatus() {
            let status = '-'
            if(this.ignition){
                status = this.$t('Units.movements.Ignition on')
            }else
            if(this.isMoving){
                status = this.$t('Units.movements.Moving')
            }else
            if(this.isParked){
                status = this.$t('Units.movements.Stopped')
            }
            return status
        },
    },
    mounted() {
        this.$emit('update:showMap', true)
    }
}
</script>

<style scoped>

</style>